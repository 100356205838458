<template>
  <section id="add-surgery-file" >
    <button v-b-modal.surgery-files-modal>
      <Clip class="icon ml-1" />
    </button>
    <SurgeryFilesModal 
      @reload-files="onAttachFile"
      :surgeryInformationId="surgeryId" :patientId="patientId"
    /> 
  </section>
</template>

<script>
export default {
  name: 'SurgeryFiles',

  components: {
    Clip: () => import('@/assets/icons/paperclip2.svg'),
    SurgeryFilesModal: () => import('@/components/SurgeryCenter/SurgeryInformation/Modal/SurgeryFilesModal.vue'),
  },

  props: {
    surgeryId: String,
    patientId: String,
  },

  data: () => ({
    selectedFile: null,
  }),

  methods: {
    onAttachFile () {
      this.$emit('attach-file', true)
    }
  }
}
</script>

<style lang="scss">
#add-surgery-file {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
    transform: scale(0.9);
  }
  
}
</style>